import { useEffect } from 'react';
import * as React from 'react'
import './App.css';
// import outerBot from './images/outer-bot.png'
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Login from './components/Login';
import Chat from './components/Chat';
import ResponsiveDrawer from './components/RightDrawer/ResponsiveDrawer';
import { useData } from './DataContext';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { createUser } from './API/UserApi';



function App({ msalInstance }) {
  const { boders, themeChanger, login } = useData()
  const width = window?.innerWidth < 700;
  const boderline = width || boders;
  console.log('borderline', boderline)
  console.log('login', login)
  console.log('boaders', boders)
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const items = { ...localStorage };

  console.log('localstorageitems', items)

  // console.log('preffered mode',prefersDarkMode)

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  var request = {
    scopes: ["User.Read"],
  };

  msalInstance.acquireTokenSilent(request).then(tokenResponse => {
    // Do something with the tokenResponse
    console.log('tokenresponse', tokenResponse?.account)
    const storedUser = localStorage.getItem('Bearer Token');
    console.log('storeuser', storedUser)
    console.log('username', tokenResponse?.account?.username)
    // const data = {
    //   Name:tokenResponse?.account?.name,
    //   Email_User:tokenResponse?.account?.username
    // }
    // if(tokenResponse?.account?.username ){
    //   // if(storedUser == undefined || storedUser == null){
    //     console.log('codework')


    //     createUser(data);
    //   // }
    // }
  }).catch(async (error) => {
    if (error instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      return msalInstance.acquireTokenPopup(request);
    }
    // handle other errors
  })



  return (
    <>
      <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="containerfluids">
            <div className={`main_parent_div ${themeChanger}`}
            // style={{borderWidth:boderline && login?"0px 12px 0px 80px":"0px 12px 0px 12px"}}
            >
              <div className={`parent-top ${themeChanger}`}>
                <Pages />
              </div>
              <div className={`copyrights ${themeChanger}`} style={{ borderWidth: boders || login ? "0px 0px 0px 0px" : "0px 0px 0px 80px" }}>
                <p className='capyrights_para'>
                  InfusAi depends on third-party models and the
                  information generated may not be accurate or offensive
                  information that doesn't represent InfusAi's views. &nbsp;
                </p>
              </div>
            </div>
          </div>
        </ThemeProvider>
      </MsalProvider>
      <ToastContainer />
    </>
  );
}

const Pages = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [data, setData] = React.useState()
  console.log('isauthenticated', data)
  // const {setLogin,login}= useData()

  useEffect(() => {
    if (!isAuthenticated) {
      instance.ssoSilent({
        scopes: ["user.read"],
        // loginHint: "", 
      }).then((response) => {
        instance.setActiveAccount(response.account);
        setData(response.account)

      }).catch(error => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.loginRedirect();
        }
      });
    }

    // eslint-disable-next-line
  }, []);


  return (
    <Router>
      <AuthenticatedTemplate>
        {/* <SideBarCharts> */}
        <ResponsiveDrawer>
          <Routes>
            <Route path="/rooms/:roomId" element={<Chat />} />
            <Route path="/" element={<Chat />} />
          </Routes>
          {/* </SideBarCharts> */}
        </ResponsiveDrawer>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />

      </UnauthenticatedTemplate>
    </Router>
  )
}

export default App;
