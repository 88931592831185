import { makeStyles } from '@material-ui/core/styles';
import { ElectricalServicesRounded } from '@mui/icons-material';
import { useData } from '../DataContext';
import { useEffect } from 'react';
import { useState } from 'react';

const useStyles = makeStyles(() => ({
    customSelect: {
        width: '100%',
        padding: '10px 30px 10px 12px',
        fontSize: '12px',
        fontWeight: 400,
        border: '1px solid rgba(120, 130, 140, 0.13)',
        borderRadius: '20px',
        // backgroundColor: '#111896',
        color: '#747d8a',
        appearance: 'none',
        backgroundImage: `url('data:image/svg+xml,${encodeURIComponent(
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ced4da" width="18px" height="18px"><path d="M7 10l5 5 5-5H7z"/></svg>'
        )}')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 10px center',
        '&:focus': {
            backgroundColor: '#fff',
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            outline: 0,
        },
    },
}));

const SelectModel = ({ hideHeader }) => {
    const LLAMA_URL = "https://aidemo.infusai.com:8502"
    const LANGC_URL = "https://aidemo.infusai.com:9002"
    const SCRAP_URL = "https://aidemo.infusai.com:8503"
    const CSV_URL = "https://aidemo.infusai.com:8504"
    // const LLAMA_URL = "https://aidemo.infusai.com8501"
    const [url,setUrl]=useState(LANGC_URL);

    const classes = useStyles();
    const { scrapUrl,serverUrl,setServerUrl,themeChanger,setUrlName } = useData(LANGC_URL)

    useEffect(()=>{
        setServerUrl(url)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[url])

    // console.log('serverurl select',serverUrl)

    const handleSelectChange = (e) => {
        // console.log('url',e?.target?.value)
        if(e.target.value === LLAMA_URL ){
            setUrlName('Text Model v1');
        }else if(e.target.value === LANGC_URL ){
            setUrlName('Text Model v2')
        }else if(e.target.value === SCRAP_URL){
            setUrlName('Web Scrapping')
        }else if(e.target.value === CSV_URL){
            setUrlName('Data Model')
        }
        setUrl(e.target.value)
        setServerUrl(e.target.value);
    };

    return (
        
        <div className='selection_bar'>
            {!hideHeader && (
                <span htmlFor="dropdown-label">
                    <ElectricalServicesRounded />
                    <span className='menu-text'>Select Model</span>
                </span>
            )}
            <select
                id="dropdown-label"
                value={serverUrl}
                onChange={handleSelectChange}
                style={{backgroundColor:themeChanger === 'dark-theme'? '#111111':'#ffffff'}}
                className={classes.customSelect}
            >
                <option value={LANGC_URL}>Text Model v2</option>
               {/* // <option value={LLAMA_URL}>Text Model v1</option> */}
                {scrapUrl ? <option disabled value={SCRAP_URL}>Web - {scrapUrl}</option> : <option disabled value={SCRAP_URL}>Web Scrapping</option>}
                <option disabled value={CSV_URL}> Data Model</option>
            </select>
        </div>
    );
};

export default SelectModel;
