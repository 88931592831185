import './Login.css'
import botImg from '../images/bot.png'
import { useMsal } from '@azure/msal-react';
import { useState } from 'react';

const Login = () => {
    const { instance } = useMsal();
    const[userdata,setuserData]= useState()
    console.log('user',userdata)

    const handleSubmit = (e) => {
        e.preventDefault();
        const user = instance.loginRedirect({
            scopes: ['user.read'],
        });
        setuserData(user)
        // console.log('user',user)

    };

    return (
        <div className="top">
            <div className="bot">
                <img src={botImg} alt="Bot" className="img-bot" />
            </div>
            <h2 className="text-white mt-3">Welcome to</h2>
            <h6 className="text-white">InfusAI</h6>
            <form
             onSubmit={handleSubmit}
              className="col-md-3 custom-form">
                <button type="submit" className="start-btn mt-3">Login</button>
            </form>
        </div>
    )
}

export default Login