import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore

const firebaseConfig = {
    apiKey: "AIzaSyATI9QXwDSKTilZmXfe7z7zbBal__rNZoc",
    authDomain: "ai-project-c3b3f.firebaseapp.com",
    projectId: "ai-project-c3b3f",
    storageBucket: "ai-project-c3b3f.appspot.com",
    messagingSenderId: "366648978925",
    appId: "1:366648978925:web:137d94d6f4724f92898be4"
};

const firebaseApp = firebase.initializeApp(firebaseConfig)
const db = firebaseApp.firestore()
const auth = firebase.auth()
const provider = new firebase.auth.GoogleAuthProvider()

export { auth, provider }
export default db