// DataContext.js

import React, { createContext, useContext, useState } from 'react';

const DataContext = createContext();

export const useData = () => {
  return useContext(DataContext);
};

export const DataProvider = ({ children }) => {


  const [data, setData] = useState([]); // Replace c'initialData' with your actual data
  const [view, setView] = useState(false)
  const [views, setViews] = useState(false)
  const [boders, setBorders] = useState(false)
  const [themeChanger, setThemeChanger] = useState(true)
  const [scrapUrl, setScrapUrl] = useState('')
  const [selectiondata,setSelectionData]= useState([])
  const [login,setLogin]=useState(false)
  const[urlname,setUrlName]= useState('Text Model v2');
  const LLAMA_URL = "https://aidemo.infusai.com:8501"

  const [serverUrl, setServerUrl] = useState(LLAMA_URL)
  // const serverUrl = "https://aidemo.infusai.com:8502"
  


  const updateData = (newData) => {
    setData(newData);
  };

  return (
    <DataContext.Provider value={{ data, updateData, view, setView, views, setViews, boders, setBorders, themeChanger, setThemeChanger, scrapUrl, setScrapUrl,serverUrl,setServerUrl,login,setLogin,selectiondata,setSelectionData,urlname,setUrlName}}>
      {children}
    </DataContext.Provider>
  );
};
