import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useState } from 'react';
import { Close } from '@material-ui/icons';
import { useData } from '../DataContext';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '2%'
    },
    paper: {
        width: '80vw',
        height: '80vh',
        backgroundColor: '#111111',
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));


const AvatarModal = ({ children, src }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const {themeChanger} = useData();

    return (
        <>
            <button onClick={() => setOpen(true)} className={`send ${themeChanger}`}>
                {children}
            </button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={classes.paper}>
                    <div className="avatar-flex">
                        <div className="modal-header">
                            <h4 className="modal-title avatar-title" id="transition-modal-title">Avatar</h4>
                            <a href={src} className="btn waves-effect waves-light btn-rounded btn-dark small btn-md" target='_blank' rel="noreferrer">Open Avatar</a>
                        </div>
                        <Close onClick={() => setOpen(false)} className='new-chat-close-btn' style={{ color: 'white' }} />
                    </div>
                    <iframe src={src} frameborder="0" width="100%" title='avtar_img' height="80%"></iframe>
                </div>
            </Modal>
        </>
    )
}

export default AvatarModal