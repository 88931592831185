import '../SideBarCharts/sidebarcharts.css'
import db from '../../firebase'
import { AiOutlinePlus } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useState } from 'react';
import { Close } from '@material-ui/icons';
import { useData } from '../../DataContext';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginTop: '10px'
    },
    paper: {
        width: '500px',
        backgroundColor: '#111111',
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const NewChat = ({ name }) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [roomName, setRoomName] = useState('');
    const {themeChanger}= useData()

    const createChat = async () => {
        const username = name;
        if (roomName) {
            const newRoomRef = await db.collection('users').doc(username).collection('rooms').add({
                roomname: roomName
            })
            const newRoomId = newRoomRef.id;
            navigate(`/rooms/${newRoomId}`);
            setOpen(false);
            setRoomName('');
        }
    }
    
    return (
        <>
            <div className="create_chat">
                <button className={`create_chat_btn ${themeChanger}`} onClick={() => setOpen(true)}> <AiOutlinePlus /> &nbsp;  Add New Chat</button>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                            <div class="header-modal">
                                <h4 class="modal-title" style={{ color: 'white' }}>Add New Chat</h4>
                                <Close onClick={() => setOpen(false)} className='new-chat-close-btn' style={{ color: 'white' }} />
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <input class="form-control input-new-chat" style={{ border: 'none' }} placeholder="Enter Chat title" type="text" name="category-name" value={roomName} onChange={e => setRoomName(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div class="footer-modal">
                                <button type="button" class="btn btn-primary waves-effect waves-light save-category btn-sm" onClick={createChat}>Save</button>
                                <button type="button" class="btn btn-white closebtn-new-chat btn-sm" onClick={() => setOpen(false)}>Close</button>
                            </div>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}

export default NewChat