import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { DataProvider } from './DataContext';

const pca = new PublicClientApplication({
    auth: {
        // clientId: '75c26835-85d0-4199-81d0-704c138937e3',
        // authority: 'https://login.microsoftonline.com/e2c10e05-53cf-433b-8952-6a8b394aa299',
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri: '/',
        postLogoutRedirectUri: '/',
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPII) => {
                console.log(message)
            },
            logLevel: "Verbose"
        }
    }
});

pca.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
        pca.setActiveAccount(event.payload.account);
    }
});

ReactDOM.render(
    <>
    <DataProvider>
        <App msalInstance={pca} />
    </DataProvider>
    </>,
    document.getElementById('root')
);