// import sendImg from '../images/ico-send-msg.png'

import { useData } from "../DataContext"

const InitialContent = ({ message, sendPreMessage }) => {
    const {themeChanger}=useData()
    return (
        <>
            <div className= {`initial__content ${themeChanger}`}    onClick={() => sendPreMessage(message)}>
                <span className='initial-text'>{message}</span>
                {/* <span className="send-icon"><img style={{ width: '20px' }} src={sendImg} alt="mic" /></span> */}
            </div>
        </>
    )
}

export default InitialContent