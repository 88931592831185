import React, { useEffect, useState } from 'react'
import { alpha, styled } from '@mui/material/styles';
// import { green } from '@mui/material/colors';
import { grey } from '@material-ui/core/colors';
import { ExitToApp, AccountCircle } from '@material-ui/icons'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import ShowDelModels from '../ShowDelModels'
import { FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import { useParams } from 'react-router-dom'
import { useMsal } from '@azure/msal-react';
import db from '../../firebase'
import '../SideBarCharts/sidebarcharts.css'
import { useData } from '../../DataContext';
import Loader from '../Loader';
// import Darktoggle from '../Darktoggle';
import DownloadDocs from '../DownloadDocs';
import Trainings from '../Trainings';
import DarkModeIcon from '@mui/icons-material/DarkMode';

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '&.MuiSwitch-switchBase.MuiSwitch-thumb': {
        // backgroundColor:'blue'
        color: grey[600]
        // color:'yellow'

    },
    '.MuiSwitch-switchBase': {
        color: grey[600]
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        //   color: grey[600],
        color: '#6ea636',
        '&:hover': {
            backgroundColor: alpha(grey[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: 'green',
        color: '#6ea636'
        // backgroundColor:'red'
    },
    '& .MuiSwitch-track': {
        backgroundColor: grey[100],
        // backgroundColor:'red'
    },
}));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                // backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
                backgroundColor: theme.palette.mode === 'dark' ? 'green' : 'grey',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        // backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        backgroundColor: theme.palette.mode === 'dark' ? 'green' : 'grey',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        // backgroundColor: theme.palette.mode === 'dark' ? 'green' : 'grey',
        borderRadius: 20 / 2,
    },
}));

const RightDrawer = () => {
    const [isLoading] = useState(false);
    const [isMenuVisible, setMenuVisible] = useState(false);
    const [isLeftMenuVisible, setLeftMenuVisible] = useState(false);
    const [name, setName] = useState(null);
    const { roomId } = useParams();
    const { instance } = useMsal();
    const { data, view, setView, views, setViews, setThemeChanger, serverUrl } = useData();
    const LLAMA_URL = "https://aidemo.infusai.com:8502"

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const [messages, selectedMessages, selectedOptions, totalCost] = data;

    console.log(messages, selectedMessages, selectedOptions, totalCost)

    const [isDarkModeEnabled, setIsDarkModeEnabled] = useState(true)

    const handlePreferredColorSchemeChange = () => {
        setIsDarkModeEnabled(!isDarkModeEnabled)
    }



    useEffect(() => {
        if (isDarkModeEnabled) {
            // enableDarkMode()
            setThemeChanger('dark-theme')
        } else {
            // disableDarkMode();
            setThemeChanger('light-theme')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDarkModeEnabled])

    const handleChange = (event) => {
        setView(event.target.checked);
    };


    const handleChanges = (event) => {
        setViews(event.target.checked)
    }

    useEffect(() => {
        const currentAccount = instance.getActiveAccount();
        const userEmail = currentAccount?.username
        setName(userEmail);
        if (currentAccount && roomId) {
            // setInitialLoad(false);
            const messagesRef = db.collection('users').doc(userEmail).collection('rooms').doc(roomId).collection("messages");
            messagesRef.orderBy('timestamp').onSnapshot((snapshot) => {
                const messagesData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                console.log('fake', messagesData)
            });
        } else {
            console.log('roomId is undefined');
            // setInitialLoad(true);
        }
    }, [instance, roomId]);

    // Add event listener to close the menu on click outside
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isMenuVisible && !event.target.closest('.menu-div')) {
                setMenuVisible(false);
            }
            if (isLeftMenuVisible && !event.target.closest('.left-menu-div')) {
                setLeftMenuVisible(false);
            }
        };
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isMenuVisible, isLeftMenuVisible]);




    return (
        <>
            {isLoading && <Loader />}
            <div className="Right_draw_side" id="menu-div">
                <ul>
                    <DownloadDocs />
                    {serverUrl === 'https://aidemo.infusai.com:8503' || serverUrl === 'https://aidemo.infusai.com:8504' ? (
                        <>
                            <Trainings serverUrl={LLAMA_URL} />
                            <ShowDelModels serverUrl={LLAMA_URL} />
                        </>
                    ) : (
                        <>
                            <Trainings serverUrl={serverUrl} />
                            <ShowDelModels serverUrl={serverUrl} />
                        </>
                    )}
                    <li>
                        <span>
                            <AttachMoneyIcon />
                            <span className="menu-text">Show Prices</span>
                            <GreenSwitch
                                checked={view}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </span>
                    </li>
                    <li style={{cursor:'no-drop'}}>
                        <span  style={{pointerEvents:'none'}}>
                            <PermIdentityIcon />
                            <span className="menu-text">Show Avatar</span>
                            <GreenSwitch
                                checked={views}
                                onChange={handleChanges}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </span>
                    </li>
                    <li>
                        <span>
                            <PaidOutlinedIcon />
                            <span className="menu-text">Session Cost : ${totalCost?.toFixed(6)}</span>
                        </span>
                    </li>
                    <li >
                        <span>
                            <DarkModeIcon/>
                        <span className='menu-text'>Change Theme</span>
                            <FormGroup>
                                <FormControlLabel
                                    control={<MaterialUISwitch sx={{ m: 1 }} checked={isDarkModeEnabled}
                                        onChange={handlePreferredColorSchemeChange} defaultChecked />}
                                />
                            </FormGroup>
                        </span>
                    </li>
                    
                    <li>
                        <span onClick={() => instance.logoutRedirect()}>
                            <ExitToApp />
                            <span className="menu-text">Logout</span>
                        </span>
                    </li>
                    <li>
                        <span>
                            <AccountCircle />
                            <span className="menu-text" style={{ fontSize: '12px' }}>{name}</span>
                        </span>
                    </li>
                    <li className="text-center mt-4">
                        <button type="button" className="btn waves-effect waves-light btn-rounded btn-dark small btn-md">Web Ver: 1.0.0.2</button>
                    </li>
                    
                </ul>
            </div>
        </>
    )
}

export default RightDrawer