import { AiFillSetting } from 'react-icons/ai'
import '../SideBarCharts/sidebarcharts.css'
import infusbotImg from '../../images/infusAi-white-logo.png'
import infusbotColorImg from '../../images/infusAi-Color-logo.png'
import collapseImg from '../../images/favicon.png'
import { MdModeComment } from 'react-icons/md'
import { useEffect, useState } from 'react'
import db from '../../firebase'
import { NavLink } from 'react-router-dom'
import { useMsal } from '@azure/msal-react';
import CloseIcon from '@mui/icons-material/Close';
import { DeleteForever, Done, Edit } from '@material-ui/icons'
import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import RightDrawer from './RightDrawer'
import { useData } from '../../DataContext'
import NewChat from './NewChat'
import SelectModel from '../SelectModel'
import { TextField } from '@mui/material'

const drawerWidth = 270;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

export default function PersistentDrawerLeft({ children }) {
  const mobileScreen = window.innerWidth > 1200
  const theme = useTheme();
  const [open, setOpen] = React.useState(true && mobileScreen);
  const [opens, setOpens] = React.useState(false);
  const { setBorders,themeChanger} = useData()

   console.log('theemess',themeChanger)

  // const LLAMA_URL = "https://aidemo.infusai.com:8502"

  // const [serverUrl, setServerUrl] = useState(LLAMA_URL)

  // const {serverUrl} = useData()
  // console.log('mobilescreen',mobileScreen)


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setBorders(open)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);
  const handleDrawerOpens = () => setOpens(true);
  const handleDrawerCloses = () => setOpens(false);

  const isOpen = true;
  // this part is all about left side integration fetching
  const [rooms, setRooms] = useState([])
  const { instance } = useMsal();
  const [name, setName] = useState(null);
  // const navigate = useNavigate();

  // console.log('rooms',rooms)


  useEffect(() => {
    const currentAccount = instance.getActiveAccount();
    if (currentAccount) {
      const userEmail = currentAccount?.username
      setName(userEmail);
      db.collection('users').doc(userEmail).collection('rooms').onSnapshot(snapshot => (
        setRooms(snapshot.docs.map(doc => (
          {
            id: doc.id,
            data: doc.data()
          }
        )))
      ))
    }
  }, [instance])


  const deleteRoomHandler = async id => {
    const currentAccount = instance.getActiveAccount();
    if (currentAccount) {
      const userEmail = currentAccount?.username;
      const roomRef = db.collection('users').doc(userEmail).collection('rooms').doc(id);
      const messagesRef = roomRef.collection('messages');
      try {
        // Get all messages in the room
        const messagesSnapshot = await messagesRef.get();
        // Delete each message
        messagesSnapshot.forEach(doc => {
          doc.ref.delete();
        });
        // Delete the room
        await roomRef.delete();
        window.location.href = "/";
        console.log(`Room ${id} and its messages deleted successfully`);
      } catch (error) {
        console.error('Error deleting room and messages:', error);
      }
    }
  }

  // const windowReloder = () => {
  //   navigate('/');
  //   window.location.reload();
  // }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ display: 'flex', justifyContent: "space-between",
        
        backgroundColor: themeChanger==="dark-theme"? '#111111': 'rgb(39, 110, 242)',
        // color:'black',
        // backgroundColor:'blue'
         }}>
          <div className='icon_btn_appbar'
            disableFocusRipple={true}
            disableRipple={true}
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, border: '3px solid red', ...(open && { display: 'none', border: '3px solid red' }) }}
          >
            {
              !open && <div className="image_div">
                <div 
                // onClick={windowReloder}
                 className='new-chat-close-btn'>
                  <img src={collapseImg} alt="Logo" className="logos" />
                </div>
              </div>
            }
            {
              !open &&
              <MenuIcon 
              sx={{ color: 'white' }}
               />
            }
          </div>
          {/* <Typography variant="h6" noWrap component="div" className="header-drawer"> */}
            {/* Persistent drawer */}
            <SelectModel hideHeader={true} />
          {/* </Typography> */}
          <div
            className='icon_btn_appbar'
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpens}
            sx={{ ...(opens && { display: 'none' }) }}
          >
            {/* <MenuIcon /> */}
            <div className="setting_cons">
              <AiFillSetting className='setting_con' />
            </div>
          </div>
        </Toolbar>
      </AppBar>

      {
        !mobileScreen ? (
          <Drawer
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            backgroundColor:themeChanger==="dark-theme"? '#111111':'white',
            // backgroundColor:'white',
            // color:'black',
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <div className="image_div">
            <div
            //  to='/' onClick={windowReloder} 
             className='new-chat-close-btn'>
            {
              isOpen ? <img src={themeChanger === 'dark-theme'? infusbotImg : infusbotColorImg} alt='' className="logo"  /> : <img src={infusbotColorImg} alt='' className="logo"  />
            }
              {/* <img src={isOpen ? infusbotImg : collapseImg} alt="Logo"
                className="logo" /> */}
            </div>

          </div>
          <div className='icon_btn_appbar' onClick={handleDrawerClose} >
            {theme.direction === 'ltr' ? <CloseIcon sx={{ color: "#a8a6a6" }} /> : <CloseIcon sx={{ color: "#a8a6a6" }} />}
          </div>
        </DrawerHeader>
        <Divider />

        <div className="sidebar_div">
          { isOpen && <NewChat name={name} />}
          <p className="recent" style={{ display: isOpen ? "block" : "none" }}>Recent</p>
          {rooms?.length !== 0 &&
            rooms?.map((room, index) => {
              return (
                <>
                  <SidoBarNavigation isOpen={isOpen} index={index} key={room.id} id={room.id} name={room.data.roomname} username={name} deleteRoom={id => deleteRoomHandler(id)} />
                  
                </>
              )
            }
            )
          }
        </div>
      </Drawer>
        ):(
          <Drawer
        sx={{
          width: open ? drawerWidth : '0',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            backgroundColor:themeChanger==="dark-theme"? '#111111':'white',
            // backgroundColor:'white',
            // color:'black',
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <div className="image_div">
            <div 
            // to='/' 
            // onClick={windowReloder}
             className='new-chat-close-btn'>
              {/* <img src={isOpen ? infusbotImg : collapseImg} alt="Logo"
                className="logo" /> */}
                {
              isOpen ? <img src={themeChanger === 'dark-theme'? infusbotImg : infusbotColorImg} alt='' className="logo"  /> : <img src={infusbotColorImg} alt='' className="logo"  />
            }
            </div>
          </div>
          <div className='icon_btn_appbar' onClick={handleDrawerClose} >
            {theme.direction === 'ltr' ? <CloseIcon sx={{ color: "#a8a6a6" }} /> : <CloseIcon sx={{ color: "#a8a6a6" }} />}
          </div>
        </DrawerHeader>
        <Divider />

        <div className="sidebar_div">
          { isOpen && <NewChat name={name} />}
          <p className="recent" style={{ display: isOpen ? "block" : "none" }}>Recent</p>
          {rooms?.length !== 0 &&
            rooms?.map((room, index) => {
              return (
                <>
                  <SidoBarNavigation isOpen={isOpen} index={index} key={room.id} id={room.id} name={room.data.roomname} username={name} deleteRoom={id => deleteRoomHandler(id)} />
                  
                </>
              )
            }
            )
          }
        </div>
      </Drawer>
        )
      }

      {/* right drawer */}
      <Drawer
        sx={{
          width: 0,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            backgroundColor: themeChanger==="dark-theme" ? '#111111' : 'white',
            // backgroundColor:'white',
            // color:'black'
          },
        }}
        variant="persistent"
        anchor="right"
        open={opens}
      >
        <DrawerHeader sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', color: themeChanger === 'dark-theme'?'white':'black' }}>
          SETTINGS
          <div className='icon_btn_appbar' onClick={handleDrawerCloses}>
            {theme.direction === 'rtl' ? <CloseIcon sx={{ color: "#a8a6a6" }} /> : <CloseIcon sx={{ color: "#a8a6a6" }} />}
          </div>
        </DrawerHeader>
        <Divider />
        <RightDrawer />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
}

function SidoBarNavigation({ isOpen, index, id,name, username, deleteRoom }) {
  const { instance } = useMsal();
  const [messages, setMessages] = useState("")
  const[chatoomname,setChatRoomName]= useState(name)
  const [editor,setEditor]= useState(false)
  const {themeChanger} = useData();
  // console.log('themeChanger',themeChanger)

   console.log('message',messages)

  useEffect(() => {
    if (id) {
      db.collection('users').doc(username).collection('rooms').doc(id)
        .collection("messages")
        .orderBy("timestamp", "desc")
        .onSnapshot(snapshot => setMessages(snapshot.docs.map(doc => doc.data())))
    }
  }, [id, username])

  const EditRoomHandler = async id => {
    const currentAccount = instance.getActiveAccount();
    if (currentAccount) {
      const userEmail = currentAccount?.username;
      const roomRef = db.collection('users').doc(userEmail).collection('rooms').doc(id);
    
      try {
        await roomRef.update({
          roomname: chatoomname,
        })
        window.location.href = "/";
        console.log(`Room ${id} updated successfully`);
      } catch (error) {
        console.error('Error updating room and messages:', error);
      }
    }
  }

  return (
    <div className="room_navigator">
      <NavLink to={`/rooms/${id}`} key={index} className= {`link ${themeChanger}`} style={{ textDecoration: "none", display: isOpen ? "flex" : "none",padding:editor?'10px 0px 0px 7px':'10px 0px 10px 7px' }} activeclassName={`active ${themeChanger}`}>
        <div style={{ display: isOpen ? "block" : "none" }} className="icon"><MdModeComment /></div>
        <div style={{ display: isOpen ? "block" : "none" }} className="link_text">
          {/* <p className="link_text_desc">{messages[0]?.message?.substring(0, 22)}&gt;</p> */}
          <p className="link_text_desc">
            <span style={{display:editor?'none':'block'}}>
              {chatoomname}
            </span>
          <TextField
          sx={{display:editor?'block':'none'}}
          id="standard-basic"
          //  label="Standard"
            variant="standard"
          size='small'
          value={chatoomname}
          onChange={(e)=>setChatRoomName(e.target.value)}
          />
          </p>
        </div>
        <div style={{display:'flex',height:'100%',gap:'0.3rem'}}>

        
        {
          isOpen && editor && <CloseIcon onClick={()=>setEditor(false)}  style={{ transform: "scale(.7)" }}/>
        }
        {
          isOpen && editor && <Done onClick={()=> EditRoomHandler(id)} style={{ transform: "scale(.7)" }} />
        }
        {
          isOpen && !editor &&
          <DeleteForever className='deleteBtn' style={{ transform: "scale(.8)" }}
            onClick={() => deleteRoom(id)}
          />
        }
        {isOpen && !editor &&
        <Edit className='deleteBtn' style={{transform:'scale(.8)'}} 
        // onClick={()=>EditRoomHandler(id)} 
        onClick={()=>setEditor(true)}
        
        />

        
        
        }
        </div>
      </NavLink>
    </div>
  )
}
