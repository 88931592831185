import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useEffect, useState } from 'react';
import { Close, Delete } from '@material-ui/icons';
import axios from 'axios';
import './ShowDelModels.css'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Loader from './Loader';
import { Button } from '@material-ui/core';
import { useData } from '../DataContext';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        // backgroundColor: '#111111',
        
        // border:'3px solid green',
        // border: '2px solid #000',
        borderRadius:'10px',
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-end',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const ShowDelModels = ({ serverUrl }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [models, setModels] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('docs');
    const {themeChanger}=useData()


    const fetchModels = async () => {
        const res = await axios.get(`${serverUrl}/CountDocs`)
        console.log('doc list', res.data)
        setModels(res.data)
    }

    useEffect(() => {
        fetchModels()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        fetchModels()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serverUrl, open, refresh])

    const deleteDoc = async model => {
        console.log('model',model)
        setLoading(true)
        if (model) {
            const res = await axios.delete(`${serverUrl}/deleteDocument/${model}`)
            console.log('res',res)
            if (res?.status === 200) {
                setLoading(false)
            }
            setRefresh(!refresh)
            // alert(res.data.message)
            
            toast.success(res?.data?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: themeChanger === 'dark-theme'? "dark" :"light",
            });
            
            setLoading(false)
        }
    }

    console.log('models',models)

    return (
        <>
            <li>
                <span onClick={() => setOpen(true)} className='download_pdf'>
                    <InsertDriveFileOutlinedIcon />
                    <span className='menu-text'>Show Documents</span>
                </span>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={() => setOpen(false)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={classes.paper} style={{background:themeChanger ==='dark-theme'?'#111111':'rgb(196, 204, 208)'}}>
                                <Close onClick={() => setOpen(false)} style={{ color: 'white' }} className='new-chat-close-btn' />
                            <div style={{width:'100%'}}>
                                <Button className="modal-title" variant="text" style={{ color:activeTab==='docs'?'green': '#989a9c',outline:'none' }} onClick={()=>setActiveTab('docs')} id="transition-modal-title">Documents List</Button>
                                <Button className="modal-title" variant="text"  style={{ color:activeTab==='scrap'?'green': '#989a9c',outline:'none' }} onClick={()=>setActiveTab('scrap')} id="transition-modal-title">{models?.csvList?.length && "CSV List"}</Button>
                                {/* <Button className="modal-title" variant="text"  style={{ color:activeTab==='scrap'?'green': '#989a9c',outline:'none' }} onClick={()=>setActiveTab('url')} id="transition-modal-title">URL List</Button> */}
                            </div>
                            <div className="modal-body" style={{width:'36vw'}}>
                                {loading &&
                                    <Loader />
                                }
                                {
                                    activeTab === 'docs' ?
                                        (<div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table table-striped table-bordered">
                                                        <tbody>
                                                            {models?.docLst && models?.docLst?.length !== 0 && models?.docLst?.map((model, i) => (
                                                                <tr>
                                                                    <td className="font-14">
                                                                        <h5 className="text-primary" key={i} id="transition-modal-description">{model}</h5>
                                                                    </td>
                                                                    <td>
                                                                        <Delete onClick={() => deleteDoc(model)} className='show-del-btn' />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>) : (
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-bordered">
                                                            <tbody>
                                                                {models?.csvList && models?.csvList?.length !== 0 && models?.csvList?.map((model, i) => (
                                                                    <tr>
                                                                        <td className="font-14">
                                                                            <h5 className="text-primary" key={i} id="transition-modal-description">
                                                                                {model}
                                                                                </h5>
                                                                        </td>
                                                                        <td>
                                                                            <Delete onClick={() => deleteDoc(model)} className='show-del-btn' />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                }
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </li>

        </>
    )
}

export default ShowDelModels